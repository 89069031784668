import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { JsonLd } from '../models/json-ld.model';
import { clamp } from '../utils';

@Injectable({
  providedIn: `root`,
})
export class MetaService {
  constructor(
    @Inject(DOCUMENT) protected document: Document,
    private meta: Meta,
    protected router: Router,
  ) {}

  public setTitle(title: string) {
    const trimmedTitle = title.trim();

    this.document.title = trimmedTitle;
    this.meta.updateTag({ property: `og:title`, content: trimmedTitle });
    this.meta.updateTag({ property: `og:site_name`, content: trimmedTitle });
    this.meta.updateTag({ name: `twitter:title`, content: trimmedTitle });
  }

  public setDescription(description: string) {
    const trimmedDescription = description.trim();

    this.meta.updateTag({ name: `description`, content: trimmedDescription });
    this.meta.updateTag({ property: `og:description`, content: trimmedDescription });
    this.meta.updateTag({ name: `twitter:description`, content: trimmedDescription });
  }

  public async setImage(imgLocation: string, alt?: string) {
    const imageUrl = `${environment.baseUrl ?? ``}/${imgLocation}`;

    this.meta.updateTag({ property: `og:image`, content: imageUrl });
    this.meta.updateTag({ property: `og:image:secure_url`, content: imageUrl });
    this.meta.updateTag({ name: `twitter:image`, content: imageUrl });

    const trimmedAlt = alt?.trim();
    this.meta.updateTag({ property: `og:image:alt`, content: trimmedAlt });
  }

  public getKeywords(): string[] {
    return this.meta.getTag(`name="keywords"`).content.split(`, `);
  }

  public addKeyword(keyword: string, position = 0) {
    const keywords = this.getKeywords();
    keywords.splice(clamp(position, 0, keywords.length), 0, keyword);
    this.setKeywords(keywords);
  }

  public removeKeyword(keyword: string) {
    const keywords = this.getKeywords();
    this.setKeywords(keywords.filter((item) => item !== keyword));
  }

  public setKeywords(keywords: string[]): void {
    const combinedKeywords = keywords.filter((item, index) => keywords.indexOf(item) === index).join(`, `);
    this.setKeywordsByString(combinedKeywords);
  }

  public setKeywordsByString(keywordString: string): void {
    this.meta.updateTag({
      name: `keywords`,
      content: keywordString,
    });
  }

  public changeFavicon(href: string) {
    // Remove old link
    const oldLink = this.document.getElementById(`favicon`);
    if (oldLink) {
      this.document.head.removeChild(oldLink);
    }

    // Add new link
    const newLink = this.document.createElement(`link`);
    newLink.id = `favicon`;
    newLink.type = `image/x-icon`;
    newLink.rel = `shortcut icon`;
    newLink.href = href;
    this.document.head.appendChild(newLink);
  }

  public setLocale(locale: string) {
    this.meta.updateTag({ property: `og:locale`, content: locale });
  }

  public updateCanonicalURL() {
    // Get the URL
    const url = this.document.URL.replace(`http://`, `https://`);

    // Update canonical URL
    (this.document.getElementById(`canonical`) as HTMLLinkElement).href = url;

    // Update alternate URLs
    const alternate = this.document.getElementById(`alternate`) as HTMLLinkElement;
    if (alternate) {
      alternate.href = url.replace(`/en/`, `/nl/`);
    }
    const alternateNl = this.document.getElementById(`alternate:nl`) as HTMLLinkElement;
    if (alternateNl) {
      alternateNl.href = url.replace(`/en/`, `/nl/`);
    }
    const alternateEn = this.document.getElementById(`alternate:en`) as HTMLLinkElement;
    if (alternateEn) {
      alternateEn.href = url.replace(`/nl/`, `/en/`);
    }

    // Update og:url
    this.meta.updateTag({ property: `og:url`, content: url });
    this.meta.updateTag({ property: `twitter:url`, content: url });
  }

  public mergeUpdateJsonLd(jsonLd: Partial<JsonLd>) {
    const jsonLdElement = this.getOrCreateJsonLdElement();
    jsonLdElement.text = JSON.stringify(this.mergeJsonLd(this.extractJsonLd(jsonLdElement), jsonLd));
  }

  public setJsonLd(jsonLd: Partial<JsonLd>) {
    this.getOrCreateJsonLdElement().text = JSON.stringify(jsonLd);
  }

  public removeElementFromJsonLdByType(type: string) {
    const jsonLdElement = this.document.getElementById(`json-ld`) as HTMLScriptElement;

    if (!jsonLdElement) {
      return;
    }

    const jsonLd: JsonLd = this.extractJsonLd(jsonLdElement);
    jsonLdElement.text = JSON.stringify({
      ...jsonLd,
      '@graph': jsonLd[`@graph`].filter((item) => item[`@type`] !== type),
    });
  }

  private extractJsonLd(jsonLdElement: HTMLScriptElement): JsonLd {
    if (!jsonLdElement || !jsonLdElement.text) {
      return {
        '@context': `https://schema.org`,
        '@graph': [],
      };
    }

    return JSON.parse(jsonLdElement.text);
  }

  private getOrCreateJsonLdElement(): HTMLScriptElement {
    let jsonLdElement = this.document.getElementById(`json-ld`) as HTMLScriptElement;
    if (!jsonLdElement) {
      jsonLdElement = this.document.createElement(`script`);
      jsonLdElement.id = `json-ld`;
      jsonLdElement.type = `application/ld+json`;
      this.document.head.appendChild(jsonLdElement);
    }

    return jsonLdElement;
  }

  public createJsonLdElement(id: string, jsonLd: Partial<JsonLd>): HTMLScriptElement {
    const jsonLdElement = this.document.createElement(`script`);
    jsonLdElement.id = id;
    jsonLdElement.type = `application/ld+json`;
    jsonLdElement.text = JSON.stringify(jsonLd);
    this.document.head.appendChild(jsonLdElement);

    return jsonLdElement;
  }

  private mergeJsonLd(oldJsonLd: Partial<JsonLd>, newJsonLd: Partial<JsonLd>): Partial<JsonLd> {
    return {
      ...oldJsonLd,
      '@graph': [
        ...oldJsonLd[`@graph`].filter(
          (oldItem) => !newJsonLd[`@graph`].some((newItem) => newItem[`@type`] === oldItem[`@type`]),
        ),
        ...newJsonLd[`@graph`],
      ],
    };
  }

  public addOrUpdateArticle(
    url: string,
    baseId: string,
    locale: string,
    title: string,
    author: string,
    datePublished: string,
    dateModified: string,
    wordcount: number,
    thumbnailUrl: string,
    tags: string[],
  ) {
    this.setTitle(title);
    this.setImage(thumbnailUrl, title);
    this.addOrUpdateCustomTag({ name: `author`, content: author });
    this.addOrUpdateCustomTag({ property: `article:author`, content: author });
    this.addOrUpdateCustomTag({
      property: `article:published_time`,
      content: datePublished,
    });
    this.addOrUpdateCustomTag({
      property: `article:modified_time `,
      content: dateModified,
    });
    this.addOrUpdateCustomTag({ property: `article:tag`, content: tags.join(`, `) });

    this.mergeUpdateJsonLd({
      '@graph': [
        {
          '@type': `Article`,
          '@id': `${baseId}#article`,
          url: url,
          name: title,
          isPartOf: { '@id': baseId },
          author: {
            '@type': `Person`,
            name: author,
          },
          headline: title,
          datePublished: datePublished,
          dateModified: dateModified,
          mainEntityOfPage: {
            '@id': baseId,
          },
          wordCount: wordcount,
          publisher: { '@id': `${baseId}#corporation` },
          inLanguage: locale,
          potentialAction: [{ '@type': `ReadAction`, target: [`${baseId}#article`] }],
          thumbnailUrl: thumbnailUrl,
          articleSection: tags,
        },
      ],
    });
  }

  public removeArticle() {
    this.addOrUpdateCustomTag({ name: `author`, content: `Wikkl` });
    this.removeCustomTagByProperty(`article:author`);
    this.removeCustomTagByProperty(`article:published_time`);
    this.removeCustomTagByProperty(`article:modified_time`);
    this.removeCustomTagByProperty(`article:tag`);

    this.removeElementFromJsonLdByType(`Article`);
  }

  public addOrUpdateCustomTag(metaDefinition: MetaDefinition) {
    if (metaDefinition.name) {
      this.removeCustomTagByName(metaDefinition.name);
    }
    if (metaDefinition.property) {
      this.removeCustomTagByProperty(metaDefinition.property);
    }

    this.meta.addTag(metaDefinition);
  }

  public removeCustomTagByName(name: string) {
    this.meta.removeTag(`name="${name}"`);
  }

  public removeCustomTagByProperty(property: string) {
    this.meta.removeTag(`property="${property}"`);
  }
}
